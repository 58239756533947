<template>
  <v-simple-table fixed-header class="pt-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">Image</th>
          <th class="text-left caption">Name</th>
          <th class="text-left caption">Email</th>
          <th class="text-left caption">Type</th>
          <th class="text-left caption">Joined</th>
          <th class="text-left caption">Row count {{
            $store.getters['admin/countGetters'] == 0 ? data.length 
            : `${$store.getters['admin/teachersGetters'].to} of ${$store.getters['admin/teachersGetters'].total}`
            }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>
            <v-avatar color="primary-2" size="36"></v-avatar>
          </td>
          <td class="body-2">{{ item.firstname + ' '+ item.lastname }}</td>
          <td class="body-2">{{ item.email }}</td>
          <td class="body-2" width="10%">
            <div>
              <v-select dense 
              solo  
              :items="type" 
              v-model="item.type" 
              v-on:input="changeStat($event, item.id)"/>
            </div>  
          <td class="body-2">{{ $helpers.dateDiff(item.created_at) }}</td>
          <td class="d-flex align-center">
            <v-btn icon small>
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn icon small class="mx-2" @click="$emit('delete', item.id)">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn :disabled="!item.type || item.type == 'Not Sponsor'" text small @click="$emit('sync', item.id)">
              <v-icon small left>mdi-reload</v-icon>
              Sync Chat
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import  { mapActions } from 'vuex'

export default {
  props: ['data'],

  data: () => ({
    type: ['Sponsor', 'Not Sponsor']
  }),

  methods: {
    ...mapActions('admin', [
      'typeTeacherAction'
    ]),

    changeStat(stat, id) {
      console.log(stat)
      console.log(id)
      this.typeTeacherAction({
        id: id,
        type: stat
      })
    }
  }
}
</script>