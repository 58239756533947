<template>
  <v-dialog persistent max-width="400" v-model="dialog">
    <v-card rounded >
      <v-card-title class="d-flex justify-space-between align-center primary white--text">
        <div class="">New Teacher</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">FIRSTNAME</label>
          <v-text-field dense solo v-model="form.firstname"
          :error-messages="errors.firstname"/>
          <label class="caption">LASTNAME</label>
          <v-text-field dense solo v-model="form.lastname"
          :error-messages="errors.lastname"/>
          <label class="caption">MIDDLENAME</label>
          <v-text-field dense solo v-model="form.middlename"
          :error-messages="errors.middlename"/>
          <label class="caption">EMAIL</label>
          <v-text-field dense solo type="email" v-model="form.email"
          :error-messages="errors.email"/>
          <!-- <label class="caption">TYPE</label> -->
          <!-- <v-select dense solo v-model="form.type"
          :items="type"
          :error-messages="errors.type"/> -->
          <v-btn 
          block 
          outlined
          color="primary-1"
          type="submit"
          class="mt-2">Submit</v-btn>
        </v-form>
      </v-card-text>
    </v-card> 
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: ['dialog'],
  data: () => ({
    form: { 
      firstname: '',
      lastname: '',
      middlename: '',
      email: '',
      type: 'Not Sponsor'
    },
    type: ['Sponsor', 'Not Sponsor']
  }),
  computed: {
    ...mapState('admin', {
      errors: (state) => state.errors
    })
  },
  methods: {
    ...mapActions('admin',['addTeacherAction']),

    save() {
      this.addTeacherAction(this.form)
      .then(() => {
        if(this.$errors('admin')) {
          console.log('errors')
          return
        }
        this.$emit('close')
        this.$refs.addForm.reset()
        this.$emit('save')
      })
    }
  }

}
</script>