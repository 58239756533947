<template>
  <section id="student-account">
    <v-row v-if="!loading">
      <v-col cols="12" lg="10">
        <div class="text-h5 ml-2 mb-5">Teacher Accounts</div>
        <v-row no-gutters class="mb-0 pb-0">
          <v-col cols="12" lg="3">
            <v-text-field
              solo
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              v-model="$store.state[store].query"
              v-on:input="search($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-calendar"
              :items="$helpers.showDate()"
              v-model="$store.state[store].date"
              v-on:input="searchDate($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-account-multiple-outline"
              :items="$helpers.showPage()"
              v-model="$store.state[store].count"
              v-on:input="searchCount($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-btn @click="reset" small class="mt-1 mx-2" color="warning" fab>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn
              @click="dialog = !dialog"
              small
              class="mt-1"
              color="primary"
              fab
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="10" lg="11">
            <v-card flat color="transparent">
              <Table
                v-if="$store.getters['admin/countGetters'] > 0"
                :data="teachers.data"
                @delete="remove"
                @sync="sync"
              />
              <Table v-else :data="teachers" @delete="remove" @sync="sync" />
            </v-card>

            <Pagination
              v-if="teachers.total > 0"
              :store="store"
              collection="teachers"
            />
            <div v-else class="text-center body-1 mt-5">
              {{ teachers.length > 0 ? "" : "No data found" }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="body-1" v-else>Loading...</div>

    <AddTeacher
      :dialog="dialog"
      @close="dialog = !dialog"
      @save="accountSave"
    />
    <Snackbar :snack="snack" :text="snackText" @close="snack = !snack" />

    <v-overlay :value="overlay" opacity=".8">
      <div class="body-1 white--text font-weight-bold">Syncing...</div>
    </v-overlay>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Pagination from "../components/account/TeacherPaginate.vue";
import Snackbar from "../../constants/Snackbar.vue";
import AddTeacher from "../components/account/AddTeacherModal.vue";
import Table from "../components/account/TableTeacher.vue";

export default {
  components: {
    Pagination,
    AddTeacher,
    Snackbar,
    Table,
  },
  data: () => ({
    loading: true,
    dialog: false,
    store: "admin",
    snack: false,
    snackText: "",
    overlay: false
  }),
  computed: {
    ...mapState("admin", {
      teachers: (state) => state.teachers,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions('admin', [
      'syncChat'
    ]),
    fetch() {
      this.$store
        .dispatch("admin/getTeacherAction", {
          page: 0,
          query: "",
          date: "",
          count: 10,
        })
        .then(() => {
          this.loading = false;
        });
    },

    search(event) {
      var vm = this;
      vm.$store.dispatch("admin/getTeacherAction", {
        page: 0,
        query: event,
        date: vm.$store.state[vm.store].date,
        count: vm.$store.state[vm.store].count,
      });
    },

    searchDate(event) {
      var vm = this;
      vm.$store.dispatch("admin/getTeacherAction", {
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: event,
        count: vm.$store.state[vm.store].count,
      });
    },

    searchCount(event) {
      var vm = this;
      vm.$store.dispatch("admin/getTeacherAction", {
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: vm.$store.state[vm.store].date,
        count: event,
      });
    },

    reset() {
      this.$store.commit("admin/resetPageMutation");
      this.fetch();
    },

    remove(id) {
      if (confirm("Delete selected account?")) {
        this.$store.dispatch("admin/deleteTeacherAction", id);
        this.snack = true;
        this.snackText = "Account Deleted!";
      }
    },

    accountSave() {
      this.snack = true;
      this.snackText = "Account Added!";
    },

    sync(e) {
      console.log(e);
      if(confirm('Sync the conversation of this user?')) {
        this.overlay = true
        this.syncChat(e).then(() => {
          this.overlay = false
          this.snack = true
          this.snackText = 'Conversation Sync Successfully!'
        })
      }
    }
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style>
</style>